import useClaim from "client/hooks/data/user/useClaim";
import React, { createContext, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { useGetShipment } from "../../actions/shippingCheckout";
import { CheckoutDataType, ShippingCheckoutContextType } from "./types";

const initialData: ShippingCheckoutContextType = {
  checkoutData: {
    total: null,
    claimId: null,
    clientSecret: null,
    fee: null,
    paymentIntentId: null,
    returnObject: null,
    returnShipment: null,
    selectedDeliveryMethod: null,
    shipment: null,
    shippingAddressTo: null,
    shippingId: null,
    shippoRates: [],
    shippingMessages: [],
    transactionId: null,
  },
  claim: null,
  isClaimLoading: false,
  setCheckoutData: () => undefined,
  isLoading: true,
  setIsLoading: () => undefined,
};

export const ShippingCheckoutContext = createContext<ShippingCheckoutContextType>(initialData);

export default function ShippingCheckoutProvider({ children }: { children: React.ReactNode }) {
  const { search } = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [getShipment, { data: shipment, loading: shipmentLoading }] = useGetShipment();
  const [checkoutData, setCheckoutData] = useState<CheckoutDataType>(initialData.checkoutData);
  const { claim, isLoading: isClaimLoading } = useClaim(checkoutData.claimId);

  useEffect(() => {
    const queries = new URLSearchParams(search);

    const returnObject = queries.get("return_object");
    const claimId = queries.get("claim_id");
    const returnShipment = queries.get("return_shipment");
    const shippingId = queries.get("shipment_id");

    setCheckoutData(prev => ({
      ...prev,
      returnObject,
      claimId,
      returnShipment,
      shippingId,
    }));
  }, [search]);

  useEffect(() => {
    if (checkoutData.returnShipment === null) return;
    getShipment(checkoutData.returnShipment);
  }, [checkoutData.returnShipment]);

  useEffect(() => {
    if (shipment !== null && !shipmentLoading) {
      setCheckoutData(prev => ({ ...prev, shipment }));
    }
  }, [shipment, shipmentLoading]);

  const values = useMemo<ShippingCheckoutContextType>(
    () => ({
      isLoading,
      setIsLoading,
      checkoutData,
      setCheckoutData,
      claim,
      isClaimLoading,
    }),
    [checkoutData, isLoading, claim, isClaimLoading],
  );

  return (
    <ShippingCheckoutContext.Provider value={values}>{children}</ShippingCheckoutContext.Provider>
  );
}
