import React, { useContext, useEffect, useRef, useState } from "react";

import useCategories from "client/hooks/data/user/useCategories";
import scrollToTop from "client/utils/scrollToTop";
import { BigChevronRight } from "../../../../../../assets/icons/icons";
import ArrowLeft from "../../../../../../assets/ui-icons/ArrowLeft";
import Loader from "../../../../../../components/Loader";
import { LostItemCreationContext } from "../../../../../../context";
import { isMdQuery } from "../../../../../../helpers/mediaQuery";
import { colors, fontSizes, fontWeights } from "../../../../../../theme/uiTheme";
import { Button, Heading, Text } from "../../../../../../ui-components";
import { StyledIncorrectMessage } from "../OTP/styles";
import { lostItemCategoryCopy } from "./content";
import { CategoryOption, CategoryOptionsContainer, Content } from "./styles";

type CategorySelectionArgs = {
  reviewUpdate?: boolean;
};

export default function CategorySelection({ reviewUpdate = false }: CategorySelectionArgs) {
  const isMd = isMdQuery();

  const { category, setCategory, setStep, prevCategory, setPrevCategory } =
    useContext(LostItemCreationContext);

  const [hasError, setHasError] = useState<boolean>(false);
  const categories = useCategories();
  const [activeStates, setActiveStates] = useState(new Array(categories.data?.length).fill(false));
  const radioRefs = useRef<HTMLInputElement[]>([]);
  const labelRefs = useRef<HTMLLabelElement[]>([]);

  const handleKeyDown = (event, currentIndex, totalOptions) => {
    const prevIndex = currentIndex === 0 ? totalOptions - 1 : currentIndex - 1;
    const nextIndex = currentIndex === totalOptions - 1 ? 0 : currentIndex + 1;

    if (event.key === "ArrowLeft" || event.key === "ArrowUp") {
      event.preventDefault();
      labelRefs.current[prevIndex].focus();
    } else if (event.key === "ArrowRight" || event.key === "ArrowDown") {
      event.preventDefault();
      labelRefs.current[nextIndex].focus();
    }
  };

  const nextStep = reviewUpdate ? "review" : "photos";

  scrollToTop();

  useEffect(() => {
    setPrevCategory(category);
  }, []);

  const onSelect = category => {
    setHasError(false);
    setCategory(category);
  };

  const handleOptionClick = index => {
    setActiveStates(prevActiveStates => {
      const newActiveStates = [...prevActiveStates];
      newActiveStates[index] = true;
      return newActiveStates;
    });
    setTimeout(() => {
      setActiveStates(prevActiveStates => {
        const newActiveStates = [...prevActiveStates];
        newActiveStates[index] = false;
        return newActiveStates;
      });
    }, 100);
  };

  const renderCategories = () => {
    if (categories.isPending) {
      return <Loader className="mx-auto" />;
    }

    const allCategories = categories.data || [];
    const totalOptions = allCategories.length;

    return (
      <CategoryOptionsContainer hasError={hasError} data-testid="categoryOptionsContainer">
        {allCategories.map(({ id, name }, index) => (
          <React.Fragment key={id}>
            <input
              ref={el => (radioRefs.current[index] = el!)}
              type="radio"
              name="category"
              className="form-check-input"
              id={`category-${id}`}
              onChange={() => onSelect([id, name])}
              checked={category[0] === id}
            />
            <CategoryOption
              className={activeStates[index] ? "active" : ""}
              autoFocus={index === 0}
              ref={el => (labelRefs.current[index] = el!)}
              htmlFor={`category-${id}`}
              data-testid={`category-${id}`}
              tabIndex={index === 0 ? 0 : -1}
              onFocus={() => {
                onSelect([id, name]);
                handleOptionClick(index);
              }}
              onKeyDown={e => handleKeyDown(e, index, totalOptions)}
              onClick={() => handleOptionClick(index)}
            >
              <Text textAlign={"left"} fontWeight={fontWeights.normal}>
                {name}
              </Text>
              <BigChevronRight accessibilityTitle="Next" titleId="NextChevronPaginationTitle" />
            </CategoryOption>
          </React.Fragment>
        ))}
      </CategoryOptionsContainer>
    );
  };

  return (
    <Content>
      <Text
        className="mb-1"
        color={colors.primary500}
        lineHeight={1.5}
        fontWeight={fontWeights.bold}
        textAlign={isMd ? "center" : "left"}
      >
        {lostItemCategoryCopy.subheading}
      </Text>
      <Heading variant="h2" className="mb-3" textAlign={isMd ? "center" : "left"}>
        {lostItemCategoryCopy.heading}
      </Heading>
      <Text
        fontSize={{ base: fontSizes.base }}
        fontWeight={fontWeights.normal}
        lineHeight={1.5}
        textAlign={isMd ? "center" : "left"}
      >
        {lostItemCategoryCopy.supportingText}
      </Text>

      {renderCategories()}

      {hasError && (
        <StyledIncorrectMessage data-testid="requiredErrorMessage">
          {lostItemCategoryCopy.categoryRequiredErrorMessage}
        </StyledIncorrectMessage>
      )}

      <Button
        data-testid="nextButton"
        aria-label="next button"
        fullWidth={true}
        size={isMd ? "xl" : "2xl"}
        className="mt-3"
        type="submit"
        onClick={() => {
          if (category[0] === "") return setHasError(true);
          setStep(nextStep);
        }}
      >
        {reviewUpdate ? lostItemCategoryCopy.nextButtonUpdate : lostItemCategoryCopy.nextButton}
      </Button>
      <Button
        aria-label="back button"
        fullWidth={true}
        size={isMd ? "xl" : "2xl"}
        className="mt-3"
        data-testid="backButton"
        variant="outline"
        onClick={() => {
          if (reviewUpdate) {
            setCategory(prevCategory);
            setStep("review");
          } else {
            setStep("date");
          }
        }}
      >
        {!reviewUpdate && (
          <ArrowLeft
            className="m-2"
            accessibilityTitle={lostItemCategoryCopy.previousButton}
            titleId="GoBackArrowLeftTitle"
            height="20px"
          />
        )}
        <Text fontWeight={fontWeights.bold}>
          {reviewUpdate
            ? lostItemCategoryCopy.previousButtonUpdate
            : lostItemCategoryCopy.previousButton}
        </Text>
      </Button>
    </Content>
  );
}
