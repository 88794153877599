import BlacklistedBanner from "client/components/ReturnStatusBanner/BlacklistedBanner";
import React, { useContext, useMemo } from "react";
import Loader from "../../../components/Loader";
import ReturnStatusBanner from "../../../components/ReturnStatusBanner";
import { ClaimDetailsContext } from "../../../context/ClaimDetails";
import { ClaimStatus } from "../../../types";
import ActionBar from "./ActionBar";
import ClaimCard from "./ClaimCard";
import Header from "./Header";
import MainDisplay from "./MainDisplay";
import SecondaryDisplay from "./SecondaryDisplay";
import StatusBar from "./StatusBar";
import { Page } from "./styles";

export default function ClaimDetails() {
  const { claim, isLoading, status, pagePadding } = useContext(ClaimDetailsContext);

  const imagePosition = useMemo(() => {
    switch (true) {
      case status === ClaimStatus.NEW:
      case status === ClaimStatus.NO_MATCHES:
      case status === ClaimStatus.HAS_MATCHES:
        return "end";
      default:
        return "start";
    }
  }, [status]);

  const title = useMemo(() => {
    switch (true) {
      case status === ClaimStatus.NEW:
      case status === ClaimStatus.NO_MATCHES:
      case status === ClaimStatus.HAS_MATCHES:
      case status === ClaimStatus.CANCELED:
      case status === ClaimStatus.EXPIRED:
        return "Lost item";
      default:
        return "Found item";
    }
  }, [status]);

  return isLoading || status === undefined ? (
    <Loader />
  ) : claim === undefined ? (
    <div className="w-100 text-center">Sorry, that claim was not found</div>
  ) : (
    <Page style={{ paddingBottom: pagePadding }}>
      <Header />

      <ClaimCard>
        <StatusBar />

        <BlacklistedBanner />

        <ReturnStatusBanner />

        <MainDisplay title={title} imagePosition={imagePosition} />

        <SecondaryDisplay />
      </ClaimCard>

      <ActionBar />
    </Page>
  );
}
