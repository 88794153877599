import FasterShippingIcon from "@assets/faster_shipping.svg";
import FastShippingIcon from "@assets/fast_shipping.svg";
import HighPriorityShippingIcon from "@assets/high_priority_shipping.svg";
import StandardShippingIcon from "@assets/standard_shipping.png";
import { isSmQuery } from "client/helpers/mediaQuery";
import { ShipmentCreationResponse, ShippingOptions } from "client/types";
import { Heading, Text } from "client/ui-components";
import formatCurrency from "client/utils/formatCurrency";
import React from "react";
import Radio from "../../../../components/Inputs/Radio";
import { OptionWrapper, Price } from "./styles";

export const SHIPPING_NAME_TO_ICON = {
  [ShippingOptions.STANDARD]: StandardShippingIcon,
  [ShippingOptions.FAST]: FastShippingIcon,
  [ShippingOptions.FASTER]: FasterShippingIcon,
  [ShippingOptions.FASTEST]: HighPriorityShippingIcon,
  [ShippingOptions.HIGH_PRIORITY]: HighPriorityShippingIcon,
};

type DeliveryOptionProps = {
  rate: ShipmentCreationResponse["shipping_options"]["rates"][0];
  hasRadio?: boolean;
  radioOptions?: React.PropsWithoutRef<JSX.IntrinsicElements["input"]>;
  country?: string;
  dataTestId?: string;
};

export default function DeliveryOption({
  rate,
  hasRadio,
  radioOptions,
  country,
}: DeliveryOptionProps) {
  const RadioWrapper = hasRadio ? Radio : "div";
  const isSm = isSmQuery();
  return (
    <OptionWrapper key={rate.object_id} className="d-flex flex-row" data-testid={`${rate.name}`}>
      <RadioWrapper
        className="flex-grow-1 py-35 ps-35 pe-2"
        {...radioOptions}
        data-testid="radioButton"
      >
        <div className="d-flex flex-row align-items-center gap-sm-25 gap-2">
          <div
            style={{
              width: isSm ? 48 : 38,
              height: isSm ? 48 : 38,
              borderRadius: 8,
              background: "#F3F9FF",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexShrink: 0,
              alignSelf: "start",
            }}
          >
            <img
              alt={`Icon for ${rate.name} shipping option`}
              src={SHIPPING_NAME_TO_ICON[rate.name]}
              width={isSm ? "24px" : "18px"}
              height={isSm ? "24px" : "18px"}
            />
          </div>
          <div className="d-flex flex-column">
            <Heading variant="h5" as="h5" style={{ lineHeight: "20px" }}>
              {rate.name}
            </Heading>
            {isSm && <Text lineHeight="20px">{rate.delivery_estimate || rate.duration_terms}</Text>}
          </div>
        </div>
        {!isSm && (
          <Text lineHeight="20px" style={{ marginTop: 4 }}>
            {rate.delivery_estimate || rate.duration_terms}
          </Text>
        )}
      </RadioWrapper>
      <Price className="d-flex align-items-center justify-content-center" data-testid="rate">
        <Text fontWeight={700} lineHeight="20px">
          {formatCurrency({
            amount: +rate.total,
            currency: rate.currency,
            country: country,
          })}
        </Text>
      </Price>
    </OptionWrapper>
  );
}
