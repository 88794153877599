import styled from "styled-components";

export const Content = styled.div`
  text-align: left;

  @media (min-width: ${props => props.theme.ui.breakpoints.md}) {
    margin: 0 auto;
    max-width: 400px;
  }

  @media (min-width: ${props => props.theme.ui.breakpoints.lg}) {
    max-width: 576px;
    width: 576px;
  }
`;

export const CategoryOptionsContainer = styled.div<{
  hasError: boolean;
}>`
  display: flex;
  flex-direction: column;
  gap: 12px;
  border: ${props => props.hasError && `${props.theme.ui.colors.error500} solid 2px`};
  border-radius: 12px;
  padding: ${props => props.hasError && "8px"};
  margin-top: 24px;

  input[type="radio"] {
    display: none;
  }
`;

export const CategoryOption = styled.label`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px;
  justify-content: space-between;
  border: 2px solid #dce1e8;
  border-radius: 8px;
  white-space: pre-line;
  gap: 8px;
  cursor: pointer;
  position: relative;

  &:focus-visible {
    outline: none;
  }

  &:focus-visible::after {
    content: "";
    width: calc(100% + 18px);
    height: calc(100% + 18px);
    background-color: transparent;
    position: absolute;
    top: -9px;
    left: -9px;
    border-color: ${props => props.theme.colors.primaryBlue};
    border-style: solid;
    border-width: 2px;
    border-radius: inherit;
  }

  &:hover {
    border: 2px solid ${props => props.theme.colors.primaryBlue};
    background: #f3f9ff;
  }

  input[type="radio"]:checked + & {
    border: 3px solid ${props => props.theme.colors.primaryBlue};
    background: #f3f9ff;
  }

  &.active {
    transform: scale(0.925);
  }
`;

export const AdditionalCategoryOptionsContainer = styled.div<{
  hasError: boolean;
}>`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 12px;
  border: ${props => props.hasError && `${props.theme.ui.colors.error500} solid 2px`};
  border-radius: 12px;
  padding: ${props => props.hasError && "8px"};

  input[type="radio"] {
    display: none;
  }
`;

export const AdditionalCategoryOption = styled.label`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 20px;
  justify-content: space-between;
  border: 2px solid #dce1e8;
  border-radius: 8px;
  flex: 1 0 47%;
  white-space: pre-line;
  gap: 8px;
  cursor: pointer;
  position: relative;

  &:focus-visible {
    outline: none;
  }

  &:focus-visible::after {
    content: "";
    width: calc(100% + 18px);
    height: calc(100% + 18px);
    background-color: transparent;
    position: absolute;
    top: -9px;
    left: -9px;
    border-color: ${props => props.theme.colors.primaryBlue};
    border-style: solid;
    border-width: 2px;
    border-radius: inherit;
  }

  &:hover {
    border: 2px solid ${props => props.theme.colors.primaryBlue};
    background: #f3f9ff;
  }

  &.active {
    transform: scale(0.925);
  }

  input[type="radio"]:checked + & {
    border: 3px solid ${props => props.theme.colors.primaryBlue};
    background: #f3f9ff;
  }
`;
