import astronautImg from "client/assets/astronaut.svg";
import React, { useContext, useMemo } from "react";
import BlankMessage from "../../../../components/BlankMessage";
import Loader from "../../../../components/Loader";
import { LostItemCreationContext } from "../../../../context";
import UserPageLayout from "../../../../ui-components/Layouts/UserPage";
import { Wrapper } from "../styles";
import {
  DateSelection,
  DescriptionInput,
  NameInput,
  PhotosUpload,
  ReviewClaim,
} from "./components";
import CategorySelection from "./components/CategorySelection";
import ContactDetailsNew from "./components/ContactDetails";
import OTP from "./components/OTP";
import SubmittedConfirmation from "./components/SubmittedConfirmation";

export default function LostItemForm() {
  const { step, isLoading, submittedClaim } = useContext(LostItemCreationContext);

  const content = useMemo<React.ReactNode>(() => {
    switch (step) {
      case "date":
        return <DateSelection />;
      case "category":
        return <CategorySelection />;
      case "photos":
        return <PhotosUpload />;
      case "description":
        return <DescriptionInput />;
      case "contact":
        return <ContactDetailsNew />;
      case "review":
        return <ReviewClaim />;
      case "nameUpdate":
        return <NameInput reviewUpdate />;
      case "dateUpdate":
        return <DateSelection reviewUpdate />;
      case "categoryUpdate":
        return <CategorySelection reviewUpdate />;
      case "photosUpdate":
        return <PhotosUpload reviewUpdate />;
      case "descriptionUpdate":
        return <DescriptionInput reviewUpdate />;
      case "contactUpdate":
        return <ContactDetailsNew reviewUpdate />;
      case "otp":
        return <OTP />;
      case "name":
        return <NameInput />;
      case "submitted":
        return submittedClaim ? (
          <SubmittedConfirmation />
        ) : (
          <BlankMessage alt="Boomerang astronaut" image={String(astronautImg)} title={"Error"}>
            We encountered an error submitting your claim. Please contact support at{" "}
            <a href="mailto:support@thanksboomerang.com">support@thanksboomerang.com</a>
          </BlankMessage>
        );
      default:
        alert(`Error: "${step}" step not implemented`);
    }
  }, [step, submittedClaim]);

  return (
    <UserPageLayout showFooter>
      <Wrapper withCityView>{isLoading ? <Loader className="mx-auto" /> : content}</Wrapper>
    </UserPageLayout>
  );
}
