import { ClaimDetailsContext } from "client/context/ClaimDetails";
import { isBlacklistedFromShipping } from "client/context/ClaimDetails/isBlacklistedFromShipping";
import Heading from "client/ui-components/Heading";
import Text from "client/ui-components/Text";
import React, { useContext } from "react";
import { Wrapper } from "../styles";

export default function BlacklistedBanner() {
  const { claim } = useContext(ClaimDetailsContext);

  const isBlacklisted = isBlacklistedFromShipping(
    claim?.category.name ?? "",
    claim?.lost_location.partner.slug ?? "",
  );

  if (!isBlacklisted) return null;
  return (
    <Wrapper className="flex-grow-1 p-35 px-md-45 d-flex flex-column gap-25 mw-100">
      <div className="d-flex flex-row gap-25">
        <div className="d-flex flex-column">
          <Heading as="h3" variant="h4" size="xs" className="mb-0 fw-bold fs16">
            Return Process
          </Heading>

          <Text>
            Universal Studios Orlando{" "}
            <span className="text-decoration-underline fw-bold">does not ship</span> credit cards
            and debit cards. You will need to pick these items up in person. If you believe your
            item has been incorrectly categorized, please reach out to{" "}
            <a href="mailto:help@thanksboomerang.com" style={{ fontWeight: 700 }}>
              help@thanksboomerang.com
            </a>
            .
          </Text>
        </div>
      </div>
    </Wrapper>
  );
}
