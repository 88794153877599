import uiTheme from "client/theme/uiTheme";
import { Heading, Link, Typography } from "client/ui-components";
import { isoCodeToAbbr } from "client/utils/countries";
import React, { useCallback, useContext, useEffect } from "react";
import config from "../../../../../config";
import {
  getShipment,
  useCreateMailingAddress,
  useCreateShipment,
  usePatchMailingAddress,
  usePatchShipment,
} from "../../../../actions/shippingCheckout";
import AddressForm, { AddressFormDataType } from "../../../../components/AddressForm";
import Loader from "../../../../components/Loader";
import { Label } from "../../../../components/typography";
import { useAuthState } from "../../../../context/auth";
import { ShippingCheckoutContext } from "../../../../context/ShippingCheckout";
import useGetStates from "../../../../hooks/data/user/useGetStates";
import { AddressError, BasicInfoDetailWrapper, StyledParagraph, UserInfo } from "./styles";

type Props = React.PropsWithoutRef<JSX.IntrinsicElements["div"]> & {
  setCurrentIndex: React.Dispatch<React.SetStateAction<number>>;
};

export default function BasicInfoDetail({ className, setCurrentIndex, ...props }: Props) {
  const isCheckoutDisabled = config["disableShippingCheckout"];
  const { setIsLoading, isLoading, setCheckoutData, checkoutData, claim } =
    useContext(ShippingCheckoutContext);
  const { user } = useAuthState();
  const [
    createMailingAddress,
    {
      data: createdMailingAddress,
      loading: isMailingAddressLoading,
      error: createMailingAddressError,
    },
    resetMailingAddress,
  ] = useCreateMailingAddress();
  const [
    createShipment,
    { data: shipmentDetails, loading: shipmentDetailsLoading, error: createShipmentDetailsError },
  ] = useCreateShipment();
  const [
    patchShipment,
    {
      data: patchedShipmentDetails,
      loading: patchedShipmentDetailsLoading,
      error: patchedShipmentDetailsError,
    },
  ] = usePatchShipment();
  const [
    patchMailingAddress,
    {
      data: patchedMailingAddress,
      loading: isPatchedMailingAddressLoading,
      error: patchedMailingAddressError,
    },
    resetPatchedMailingAddress,
  ] = usePatchMailingAddress();

  const country = claim?.lost_location.address.country_code;
  const countryAbbr = country ? isoCodeToAbbr[country] || country : "-";

  const { states } = useGetStates("United Kingdom");
  const GBState = states?.find(state => state.iso_code === "GB");

  const handleSubmit = useCallback(
    (data: AddressFormDataType) => {
      setCheckoutData(prev => ({ ...prev, shippingAddress: data }));
      const isUSSelected = data.country === "US";
      const mailObject = isUSSelected
        ? {
            address1: data.address1,
            address2: data.address2,
            postal_code: data.zipCode,
            city: data.city,
            region_code: data.state.id,
            country_code: data.country,
          }
        : {
            address1: data.address1,
            address2: data.address2,
            postal_code: data.zipCode,
            region_code: GBState?.iso_code ?? data.state.id,
            country_code: data.country,
            city: data.city,
          };

      if (checkoutData.shippingAddressTo === null) {
        createMailingAddress(mailObject);
      } else {
        if (checkoutData.shippingAddressTo)
          patchMailingAddress(checkoutData.shippingAddressTo, mailObject);
      }
    },
    [
      setCheckoutData,
      GBState?.id,
      checkoutData.shippingAddressTo,
      createMailingAddress,
      patchMailingAddress,
    ],
  );

  useEffect(() => {
    if (checkoutData.shippingId !== null) {
      getShipment(checkoutData.shippingId).then(shipment => {
        if (shipment)
          setCheckoutData(prev => ({
            ...prev,
            shippingAddressTo: shipment.shipping_address_to,
          }));
      });
    }
  }, [checkoutData.shippingId]);

  useEffect(() => {
    if (createdMailingAddress !== null) {
      setCheckoutData(prev => ({
        ...prev,
        shippingAddressTo: createdMailingAddress.id,
      }));
    }
  }, [createdMailingAddress]);

  useEffect(() => {
    if (checkoutData.returnObject === null) return;

    if (
      createdMailingAddress !== null &&
      !isMailingAddressLoading &&
      checkoutData.shippingId === null
    ) {
      createShipment({
        shipping_address_to: createdMailingAddress.id,
        return_object: checkoutData.returnObject,
      });
    }
  }, [
    createdMailingAddress,
    isMailingAddressLoading,
    checkoutData.shippingId,
    checkoutData.returnObject,
  ]);

  useEffect(() => {
    if (checkoutData.returnObject === null) return;

    if (
      patchedMailingAddress !== null &&
      !isPatchedMailingAddressLoading &&
      checkoutData.shippingId !== null
    ) {
      patchShipment(checkoutData.shippingId, {
        shipping_address_to: patchedMailingAddress.id,
        return_object: checkoutData.returnObject,
      });
    }
  }, [
    patchedMailingAddress,
    isPatchedMailingAddressLoading,
    checkoutData.shippingId,
    checkoutData.returnObject,
  ]);

  useEffect(() => {
    if (shipmentDetails === null && patchedShipmentDetails === null) return;

    /* Update after patch */
    if (patchedShipmentDetails !== null) {
      setCheckoutData(prev => ({
        ...prev,
        returnObject: patchedShipmentDetails.return_object,
        shippingAddressTo: patchedShipmentDetails.shipping_address_to,
        shippoRates: patchedShipmentDetails.shipping_options.rates,
        shippingMessages: patchedShipmentDetails.shipping_options.messages,
        shippingId: patchedShipmentDetails.id,
      }));
    } else if (shipmentDetails !== null) {
      setCheckoutData(prev => ({
        ...prev,
        returnObject: shipmentDetails.return_object,
        shippingAddressTo: shipmentDetails.shipping_address_to,
        shippoRates: shipmentDetails.shipping_options.rates,
        shippingMessages: shipmentDetails.shipping_options.messages,
        shippingId: shipmentDetails.id,
      }));
      const urlWithShipmentId = `${window.location.href}&shipment_id=${shipmentDetails.id}`;
      window.history.replaceState(
        {
          ...window.history.state,
          as: urlWithShipmentId,
          url: urlWithShipmentId,
        },
        "",
        urlWithShipmentId,
      );
    }

    setCurrentIndex(prev => prev + 1);
  }, [shipmentDetails, patchedShipmentDetails]);

  useEffect(() => {
    setIsLoading(
      shipmentDetailsLoading ||
        isMailingAddressLoading ||
        isPatchedMailingAddressLoading ||
        patchedShipmentDetailsLoading,
    );
  }, [
    shipmentDetailsLoading,
    isMailingAddressLoading,
    isPatchedMailingAddressLoading,
    patchedShipmentDetailsLoading,
  ]);

  return (
    <BasicInfoDetailWrapper {...props} className={`row w-100 ${className ? className : ""}`}>
      <div className="col-lg-6 d-flex flex-column gap-4">
        <Heading variant="h5" fontWeight={700} style={{ lineHeight: "16px" }}>
          Contact info
        </Heading>
        <div className="d-flex flex-column gap-2">
          <Label>Name</Label>
          <UserInfo>{user.full_name}</UserInfo>
        </div>

        <div className="d-flex flex-column gap-2">
          <Label>Email</Label>
          <UserInfo>{user.email}</UserInfo>
        </div>

        <div className="d-flex flex-column gap-2">
          <Label>Phone number</Label>
          <UserInfo>{user.phone_number}</UserInfo>
        </div>
      </div>
      {claim ? (
        <div className="col-lg-6 mt-5 mt-lg-0 d-flex flex-column gap-25">
          <div className="d-flex w-100 flex-row gap-1 justify-content-between align-items-center">
            <Heading variant="h5" fontWeight={700} style={{ lineHeight: "16px" }}>
              Shipping Address
            </Heading>
          </div>
          {isCheckoutDisabled ? (
            <Typography color={uiTheme.colors.error500}>
              <Typography fontWeight={700}>NOTE:</Typography> Checkout is disabled for this
              environment.
            </Typography>
          ) : (
            <StyledParagraph>
              If you'd like to ship outside {countryAbbr}, contact{" "}
              <Link href="mailto:help@thanksboomerang.com" target="_blank">
                help@thanksboomerang.com
              </Link>{" "}
              and a customer representative will assist you.
            </StyledParagraph>
          )}
          <AddressForm
            aria-disabled={isMailingAddressLoading}
            disabled={isCheckoutDisabled || isLoading}
            id="basicInfo"
            onAddressCreation={handleSubmit}
            reset={() => {
              resetMailingAddress();
              resetPatchedMailingAddress();
            }}
            countryId={country}
          />
        </div>
      ) : (
        <Loader className="m-auto" />
      )}
      {(patchedMailingAddressError !== null || createMailingAddressError !== null) && (
        <AddressError className="text-center">
          Sorry, the address could not be verified. Please try again or enter another address.
        </AddressError>
      )}
      {patchedShipmentDetailsError !== null ||
        (createShipmentDetailsError !== null && (
          <AddressError className="text-center">
            There was an error creating your shipment. Please review your address details and try
            again. If you are still experiencing issues, contact us at{" "}
            <a href="mailto:help@thanksboomerang.com">help@thanksboomerang.com</a>.
          </AddressError>
        ))}
    </BasicInfoDetailWrapper>
  );
}
